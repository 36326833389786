import React, { useState } from 'react'
import '../Style/ContactForm.css'
import { Form, Button } from 'react-bootstrap'; // Importing React Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure Bootstrap CSS is imported
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


function ContactForm() {

  const botToken = '7796591580:AAE7qZ261hESbY8TQzXvBLLsvZmzmA1S4Rs'
  const chatId = '1021303643'
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState("")
  const [text, setText] = useState('')




  const showSuccessToast = () => {
    toast.success('Muvaffaqiyatli!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };


  const showErrorToast = () => {
    toast.error('Xato!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };




  const SendMessage = async (e) => {
    e.preventDefault()
    try {
      const newMessage = {
        chat_id: chatId,
        text: text,
        name:name,
        phoneNumber:phoneNumber
      }
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, newMessage)
      showSuccessToast()
      setName('')
      setPhoneNumber('')
      setText('')
    } catch (error) {
      showErrorToast()
    }
  }

  return (
    <section className='ContactForm'>
      <div className='Container'>
        <div className='ContactForm__wrapper'>
          <Form onSubmit={SendMessage}> {/* React Bootstrap Form component */}
            <h2 className="mb-4">Savollaringiz bo’lsa murojaat qiling</h2>

            {/* Name field */}
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Ism</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text" placeholder="Ismingizni kiriting" />
            </Form.Group>

            {/* Phone field */}
            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="tel" placeholder="Telefon raqamingizni kiriting" />
            </Form.Group>

            {/* Message field */}
            <Form.Group controlId="formMessage" className="mb-3">
              <Form.Label>Xabar</Form.Label>
              <Form.Control
                value={text}
                onChange={(e) => setText(e.target.value)}
                as="textarea" rows={4} placeholder="Xabaringizni yozing" />
            </Form.Group>

            {/* Submit button */}
            <Button variant="primary" type="submit">Yuborish</Button>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </section>
  )
}

export default ContactForm