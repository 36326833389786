import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/Test.css';
import Hero from './Hero';
import axios from '../Service/axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CONFIG from '../Service/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

function DemoTest() {
    const { ID } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const cardData = location.state?.cardData;
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to manage button disabled status

    useEffect(() => {
        axios.get(`/demoQuestion/getQuestionByLevelId/${ID}`)
            .then((response) => {
                setQuestions(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [ID]);

    const showSuccessToast = () => {
        toast.success('To\'g\'ri!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const showErrorToast = () => {
        toast.error('Xato!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const handleAnswerChange = (questionId, answer) => {
        setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answer,
        }));
    };

    const validateAnswers = (question) => {
        const userSelectedAnswer = userAnswers[question.id];
        const correctAnswer = question.correctAnswer.trim().toLowerCase();
        const formattedUserAnswer = (userSelectedAnswer || '').trim().toLowerCase();

        if (question.questionType === 'TEST') {
            return formattedUserAnswer === correctAnswer;
        }

        if (question.questionType === 'IMAGE') {
            const userTextAnswers = [...document.querySelectorAll(`input[name='question-${question.id}']`)].map(input => input.value.trim().toLowerCase());
            const isCorrect = userTextAnswers.join('') === correctAnswer;
            return isCorrect;
        }

        return false;
    };

    const handleNextQuestion = () => {
        if (isButtonDisabled) return; // Prevent multiple clicks

        setIsButtonDisabled(true); // Disable button

        if (validateAnswers(questions[currentQuestionIndex])) {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                showSuccessToast(); 
            } else {
                showSuccessToast();
                setTimeout(() => navigate(-1), 2000);
            }
        } else {
            showErrorToast(); 
            setTimeout(() => navigate(-1), 1000);
        }

        setTimeout(() => {
            setIsButtonDisabled(false); // Re-enable button after 2 seconds
        }, 2000);
    };

    const handleInputChange = (e) => {
        const input = e.target;
        const { name, value } = input;
        const maxLength = parseInt(input.getAttribute('maxlength'), 10);
        const inputs = [...document.querySelectorAll(`input[name='${name}']`)];
        const currentIndex = inputs.indexOf(input);

        if (value.length === maxLength) {
            if (currentIndex < inputs.length - 1) {
                inputs[currentIndex + 1].focus();
            }
        } else if (value.length === 0 && currentIndex > 0) {
            inputs[currentIndex - 1].focus();
        }
    };

    if (loading) {
        return (
            <div className='Loading__mb'>
                <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
            </div>
        );
    }

    if (!questions || questions.length === 0) {
        return (
            <div className='Test'>
                <div className='Container'>
                    <div className='Nores NoresTest'>
                        <h2>Savol yo`q</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <main>
            <Hero />
            <section className='Test'>
                <div className='Container'>
                    <div className='Test__wrapper'>
                        <div className='card__wrraperr'>
                            {questions.length > 0 && currentQuestionIndex < questions.length && (
                                <div className='card__wrraperr' key={questions[currentQuestionIndex].id}>
                                    {questions[currentQuestionIndex].questionType === 'TEST' ? (
                                        <div className='Test__Card'>
                                            <div className='Test__card__grid'>
                                                <h2>{questions[currentQuestionIndex].questionValue}</h2>
                                                <div className='Test__Card__otv'>
                                                    {questions[currentQuestionIndex].additiveAnswer?.map((answer, index) => (
                                                        <label htmlFor={`answer-${index}`} key={index}>
                                                            <input
                                                                type="radio"
                                                                id={`answer-${index}`}
                                                                name={`question-${questions[currentQuestionIndex].id}`}
                                                                checked={userAnswers[questions[currentQuestionIndex].id] === answer}
                                                                onChange={() => handleAnswerChange(questions[currentQuestionIndex].id, answer)}
                                                            />
                                                            <span>{answer}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='Test__card__grid'>
                                                <div className='Test__Card__grid__time'></div>
                                                <div className='Test__card__grid__btn'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={handleNextQuestion}
                                                        disabled={isButtonDisabled} // Disable button based on state
                                                    >
                                                        Keyngisi..
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : questions[currentQuestionIndex].questionType === 'IMAGE' ? (
                                        <div className='Test__Card'>
                                            <div className='Test__card__grid Test__card__grid2'>
                                                <img src={CONFIG.API_URL + questions[currentQuestionIndex].imgUrl} alt="question-img" />
                                                <div>
                                                    <span className='test__card__text2'>{questions[currentQuestionIndex].questionValue}</span>
                                                    <div className='Test__card__Bkv'>
                                                        {[...Array(questions[currentQuestionIndex].correctAnswerLength)].map((_, index) => (
                                                            <input
                                                                key={index}
                                                                type="text"
                                                                className="form-control single-letter-input"
                                                                maxLength="1"
                                                                name={`question-${questions[currentQuestionIndex].id}`}
                                                                onChange={handleInputChange}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Test__card__grid'>
                                                <div className='Test__Card__grid__time'></div>
                                                <div className='Test__card__grid__btn'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={handleNextQuestion}
                                                        disabled={isButtonDisabled} // Disable button based on state
                                                    >
                                                        Keyngisi..
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            {cardData && (
                                <Card className="custom-card" style={{ width: '12rem', borderRadius: '10px', borderLeft: '5px solid blue' }}>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <h2>{cardData.title}</h2>
                                        </div>
                                        <Card.Text className="text-muted">
                                            {cardData.educationName}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )}
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </section>
        </main>
    );
}

export default DemoTest;
