import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import '../Style/Register.css'
import axios from '../Service/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Register({ show, handleClose }) {
    const [active, setActive] = useState(false)
    const Active = () => {
        setActive(!active)
    }

    const showSuccessToast = () => {
        toast.success('Muvaffaqiyatli!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };


    const showErrorToast = () => {
        toast.error('Xato!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };


    const [name, setName] = useState('')
    const [surName, setSurName] = useState('')
    const [region, setRegion] = useState('')
    const [educId, setEducId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const Register = (e) => {
        const newData = {
            name: name,
            surname: surName,
            region: region,
            educId: educId,
            phoneNumber: `${phoneNumber.replace(/\D/g, "")}`,
            password: password
        }
        e.preventDefault()
        axios.post(`/user/register`, newData)
            .then((response) => {
                console.log(response.data);
                setName('')
                setSurName('')
                setPassword('')
                setPhoneNumber('')
                setRegion('')
                setEducId('')
                showSuccessToast()
                localStorage.setItem('token', response.data.object)
                setTimeout(() => {
                    handleClose();
                }, 1000);
            })
            .catch((error) => {
                console.log(error)
                showErrorToast()
            })
    }
    const [eduData, setEduData] = useState([])
    const GetEducation = () => {
        axios.get('/education/getAll',)
            .then((response) => {
                setEduData(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        GetEducation()
    }, [])
    return (
        <Modal className='Register' show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={Register}>
                    <Form.Group controlId="name">
                        <Form.Label>Ism</Form.Label>
                        <Form.Control
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text" placeholder="Ism" />
                    </Form.Group>

                    <Form.Group controlId="Surname">
                        <Form.Label>Familiya</Form.Label>
                        <Form.Control
                            value={surName}
                            onChange={(e) => setSurName(e.target.value)}
                            type="text" placeholder="Familiya" />
                    </Form.Group>

                    <Form.Group controlId="exampleSelect">
                        <Form.Label>Sizning ta'lim muassasangiz
                        </Form.Label>
                        <Form.Select
                            value={educId}
                            onChange={(e) => setEducId(e.target.value)}
                            aria-label="Default select example"
                        >
                            <option defaultValue>ta`lim</option>
                            {eduData?.map((i, index) => (
                                <option key={index} value={i.id}>{i.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="exampleSelect">
                        <Form.Label>Sizning viloyatingiz
                        </Form.Label>
                        <Form.Select
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                            aria-label="Default select example"
                        >
                            <option defaultValue>Viloyat</option>
                            <option value="QORAQALPOGISTON">Qoraqalpogiston</option>
                            <option value="ANDIJON">Andijon</option>
                            <option value="BUXORO">Buxoro</option>
                            <option value="JIZZAX">Jizzax</option>
                            <option value="QASHQADARYO">Qashqadaryo</option>
                            <option value="NAVOIY">Navoiy</option>
                            <option value="NAMANGAN">NAMANGAN</option>
                            <option value="SAMARQAND">Samarqand</option>
                            <option value="SURXANDARYO">Surxandaryo</option>
                            <option value="SIRDARYO">Sirdaryo</option>
                            <option value="TOSHKENT_VIL">Toshkent viloyat</option>
                            <option value="TOSHKENT_SHAX">Toshkent shaxar</option>
                            <option value="XORAZM">Xorazm</option>
                            <option value="FARGONA">Farg'ona</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Telefon raqam</Form.Label>
                        <Form.Control

                            value={phoneNumber}
                            onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, ''); // Удаляем нечисловые символы
                                const maxLength = 12; // Максимальное количество цифр после +998
                    
                                if (input.length > maxLength) return; // Ограничиваем длину
                    
                                // Форматируем номер
                                let formatted = "+998";
                                if (input.length > 3) formatted += ` ${input.slice(3, 5)}`;
                                if (input.length > 5) formatted += ` ${input.slice(5, 8)}`;
                                if (input.length > 8) formatted += ` ${input.slice(8)}`;
                    
                                setPhoneNumber(formatted);
                            }}
                            type="text"
                            defaultValue="+998"/>
                    </Form.Group>

                    <Form.Group className='PasswordEye' controlId="formBasicPassword">
                        <Form.Label>Parol</Form.Label>
                        <Form.Control
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={active ? 'password' : 'text'} placeholder="Parol" />
                        <div onClick={Active} className=''>
                            {active ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#1D4ED6" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"></path></svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#1D4ED6" d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448M248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69a64.11 64.11 0 0 0 53.49 53.49a2 2 0 0 0 1.69-3.39m16-119.7L315.87 248a2 2 0 0 0 3.4-1.69a64.13 64.13 0 0 0-53.55-53.55a2 2 0 0 0-1.72 3.39"></path><path fill="#1D4ED6" d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.5 226.5 0 0 0-71.82 11.79a4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05a96 96 0 0 1 116 116a4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24a343.8 343.8 0 0 0 67.24-77.4M256 352a96 96 0 0 1-93.3-118.63a4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.2 238.2 0 0 0 72.64-11.55a4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352"></path></svg>
                            )}
                        </div>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="Login__btn mt-3">
                        Kirish
                    </Button>
                </Form>
            </Modal.Body>
            <ToastContainer />
        </Modal>
    )
}

export default Register