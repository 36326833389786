import React, { useEffect, useState } from 'react'
import '../Style/Profile.css'
import ProfileModal from '../Components/ProfileModal'
import axios from '../Service/axios'
import ProfileFotoModal from '../Components/ProfileFotoModal'
import CONFIG from '../Service/Config'
import ReactLoading from 'react-loading';

function Profile() {
  const FotoPerson = 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/925px-Unknown_person.jpg'
  const [modal, setModal] = useState(false)
  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)
  const [loading, setLoading] = useState(true);
  const [photoModal, setPhotoModal] = useState(false); // Модал для изменения фото


  const openPhotoModal = () => setPhotoModal(true);
  const closePhotoModal = () => setPhotoModal(false);

  const  [info, setInfo] = useState([])
  const getMyInformation = () => {
    axios.get(`/user/getCurrenInformation/${0}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response)=>{
      setInfo(response.data)      
    })
    .catch((error)=>{
      console.log(error);
    })
    .finally(()=>{
      setLoading(false);
    })
  }
  useEffect(()=>{
    getMyInformation()
  },[])

  const Exit  = () => {
    localStorage.clear();
    window.location.reload();
  };
  const regions = {
    QORAQALPOGISTON: 'Qoraqalpogiston',
    ANDIJON: 'Andijon',
    BUXORO: 'Buxoro',
    JIZZAX: 'Jizzax',
    QASHQADARYO: 'Qashqadaryo',
    NAVOIY: 'Navoiy',
    NAMANGAN: 'Namangan',
    SAMARQAND: 'Samarqand',
    SURXANDARYO: 'Surxandaryo',
    SIRDARYO: 'Sirdaryo',
    TOSHKENT_VIL: 'Toshkent viloyat',
    TOSHKENT_SHAX: 'Toshkent shaxar',
    XORAZM: 'Xorazm',
    FARGONA: 'Farg`ona'
};


  if (loading) {
    return (
      <div  className='Loading'>
        <ReactLoading type="spinningBubbles" color="#1D4ED6" height={100} width={100} />
      </div>
    );
  }
  return (
    <div className='Profile'>
      <div className='Profile__title'>
        <h1>
          Profil
        </h1>
        <div className='Profile__title__grid '>
          <button onClick={openPhotoModal} className='btn btn-primary'>
           Rasm o'zgartirish
          </button>
          <button onClick={openModal} className='btn btn-primary'>
            O'zgartirish
          </button>
        </div>
      </div>
      <div className='Profile__wrapper'>
        <div className='Profile__card'>
          <img src={info.avaName ? CONFIG.API_URL + info.avaName : FotoPerson} alt="" />
          <div className='Profile__wrapper__grid'>
            <h3>
              Ism:
            </h3>
            <span>
              {info.name}
            </span>
          </div>
          <div className='Profile__wrapper__grid'>
            <h3>
              Familiya:
            </h3>
            <span>
              {info.surname}
            </span>
          </div>
          <div className='Profile__wrapper__grid'>
            <h3>
              Telefon raqam:
            </h3>
            <span>
              {info.phoneNumber}
            </span>
          </div>
          <div className='Profile__wrapper__grid'>
            <h3>
              Viloyat:
            </h3>
            <span>
            {regions[info.region] || ''}
            </span>
          </div>
          <button onClick={Exit} className='btn btn-primary profile__btn'>
            Chiqish
          </button>
        </div>
      </div>
      <ProfileModal show={modal} handleClose={closeModal} />
      <ProfileFotoModal show={photoModal} handleClose={closePhotoModal}/>
    </div>
  )
}

export default Profile